import React from 'react'

const Processing = () => {
  return (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={88}
    fill="none"
  >
    <circle cx={14.5} cy={14.5} r={7.5} className='svg-secondary' />
    <circle cx={32.5} cy={44.5} r={7.5} className='svg-primary' />
    <circle cx={73.5} cy={73.5} r={7.5} className='svg-tertery' />
    <path
      fill="#001B23"
      fillRule="evenodd"
      d="M61.264 74.307H46.058C45.44 78.587 42.053 82 37.589 82c-4.692 0-8.51-3.817-8.51-8.51 0-4.691 3.818-8.508 8.51-8.508 4.464 0 7.852 3.413 8.469 7.693h15.206l-2.125-2.124 1.154-1.154 3.517 3.517a.82.82 0 0 1 .24.577.82.82 0 0 1-.24.576l-3.517 3.518-1.154-1.154 2.124-2.124Zm-16.798-.816a6.885 6.885 0 0 0-6.877-6.877 6.885 6.885 0 0 0-6.877 6.877 6.885 6.885 0 0 0 6.877 6.877 6.885 6.885 0 0 0 6.877-6.877Z"
      clipRule="evenodd"
    />
    <path
      fill="#001B23"
      fillRule="evenodd"
      d="M21.561 43.184h2.43c.617-4.28 4.005-7.693 8.47-7.693 4.69 0 8.508 3.817 8.508 8.509s-3.817 8.51-8.509 8.51c-4.464 0-7.852-3.415-8.469-7.694h-2.43c-7.681 0-13.93 6.248-13.93 13.93 0 7.68 6.249 13.929 13.93 13.929h3.8l-2.123-2.124 1.153-1.154 3.517 3.517h.001a.82.82 0 0 1 .24.577.82.82 0 0 1-.24.576l-3.518 3.518-1.153-1.154 2.124-2.124h-3.8C12.98 74.307 6 67.327 6 58.745c0-8.58 6.98-15.561 15.561-15.561Zm4.022.816a6.885 6.885 0 0 0 6.877 6.877A6.885 6.885 0 0 0 39.337 44a6.885 6.885 0 0 0-6.877-6.877A6.885 6.885 0 0 0 25.583 44ZM40.731 15.085l-3.518 3.518-1.153-1.154 2.124-2.124H22.978c-.616 4.28-4.004 7.693-8.469 7.693C9.817 23.018 6 19.201 6 14.51S9.817 6 14.51 6c4.464 0 7.852 3.413 8.468 7.693h15.206L36.06 11.57l1.153-1.154 3.517 3.517.001.001a.82.82 0 0 1 .24.576.82.82 0 0 1-.24.576Zm-33.1-.576a6.885 6.885 0 0 0 6.878 6.878 6.885 6.885 0 0 0 6.878-6.878 6.885 6.885 0 0 0-6.878-6.877 6.885 6.885 0 0 0-6.877 6.877ZM82 73.49c0 4.693-3.817 8.51-8.51 8.51-4.691 0-8.508-3.817-8.508-8.51 0-4.691 3.817-8.508 8.509-8.508S82 68.799 82 73.49Zm-1.632 0a6.885 6.885 0 0 0-6.877-6.876 6.885 6.885 0 0 0-6.877 6.877 6.885 6.885 0 0 0 6.877 6.877 6.885 6.885 0 0 0 6.877-6.877ZM58.88 13.693h7.559c8.58 0 15.561 6.98 15.561 15.562 0 8.58-6.98 15.56-15.561 15.56H44.687l2.125 2.125-1.154 1.154-3.517-3.517-.001-.001A.82.82 0 0 1 41.9 44a.82.82 0 0 1 .24-.576l3.518-3.518 1.154 1.154-2.125 2.124H66.44c7.681 0 13.93-6.248 13.93-13.93 0-7.68-6.249-13.929-13.93-13.929H58.88c-.617 4.28-4.005 7.693-8.469 7.693-4.692 0-8.51-3.817-8.51-8.509S45.72 6 50.412 6c4.465 0 7.852 3.413 8.469 7.693Zm-15.346.816a6.885 6.885 0 0 0 6.877 6.878 6.885 6.885 0 0 0 6.877-6.878 6.885 6.885 0 0 0-6.877-6.877 6.885 6.885 0 0 0-6.877 6.877Z"
      clipRule="evenodd"
    />
  </svg>  )
}

export default Processing