import React from "react";
import Payment from "./SvgImages/Payment";
import Processing from "./SvgImages/Processing";
import Enforcement from "./SvgImages/Enforcement";
import Support from "./SvgImages/Support";
import Callcenter from "../images/call-center-access.png";
import RistekLogo from "../images/RistekLogo.png"
import "../css/style.css";
import "../css/theme.css";
import { assignURLs } from "./Site";

const Home = () => {
  const {
    paymentURL,
    processingURL,
    enforcementURL,
    customBranding,
    customLogo,
  } = assignURLs();

  const hostname = window.location.hostname;

  const isShowEnforcement = () => {
    if (
      hostname === "admin-leavenworth.netlify.app" ||
      hostname === "admin-parkocmd.netlify.app"
    ) {
      return false;
    } else {
      return true;
    }
  };
  console.log("hostname",hostname)

  const isOCMD = () => {
    if (
      hostname === "admin.parkocmd.com" ||
      hostname === "admin.parkocmd.netlify.app" ||
      hostname === "staging-admin.parkocmd.com" ||
      hostname === "staging-admin-parkocmd.netlify.app"
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="wrapper">
      <div className="header-logo">
        <a href="#">
          <img src={customLogo} height={100} />
        </a>
      </div>
      <div className="welcome-title-sub">
        <h1 className="welcome-h1">
          Welcome to {customBranding === "OCMD" ? "Ocean City" : customBranding}{" "}
          Citation Management Hub{" "}
        </h1>
        <p className="welcome-ttl-sb">Parking management made simple.</p>
      </div>
      <div className="welcome-box-main">
        <a href={paymentURL} target="_blank">
          <div className="welcome-box-main-in">
            <span className="welcome-box-icon">
              {/* <img src={Payment}/> */}
              <Payment />
            </span>
            <h5 className="welcome-h5">Payment Portal</h5>
            <p className="welcome-box-p">Motorist Portal.</p>
          </div>
        </a>
        <a href={processingURL} target="_blank">
          <div className="welcome-box-main-in">
            <span className="welcome-box-icon">
              {/* <img src={Processing}/> */}
              <Processing />
            </span>
            <h5 className="welcome-h5">Processing Portal</h5>
            <p className="welcome-box-p">Admin Portal .</p>
          </div>
        </a>
        {isShowEnforcement() && (
          <a href={enforcementURL} target="_blank">
            <div className="welcome-box-main-in">
              <span className="welcome-box-icon">
                {/* <img src={Enforcement} /> */}
                <Enforcement />
              </span>
              <h5 className="welcome-h5">Enforcement Portal</h5>
              <p className="welcome-box-p">Operation Portal.</p>
            </div>
          </a>
        )}
        <a href="https://parkloyalty.atlassian.net/servicedesk" target="_blank">
          <div className="welcome-box-main-in">
            <span className="welcome-box-icon">
              {/* <img src={Support}/> */}
              <Support />
            </span>
            <h5 className="welcome-h5">Support Portal</h5>
            <p className="welcome-box-p">Support Portal.</p>
          </div>
        </a>
        {isOCMD() && (
          <a href={"https://vergebi.com/#/auth/login"} target="_blank">
            <div className="welcome-box-main-in">
              <span className="welcome-box-icon" style={{marginBottom:"22%"}}>
                <img src={RistekLogo} />
              </span>
              <h5 className="welcome-h5">VergeBI</h5>
              <p className="welcome-box-p">Parking Analytics Platform.</p>
            </div>
          </a>
        )}
        {/* <a href="https://callcenter.parkocmd.com" target="_blank">
            <div className="welcome-box-main-in">
                <span className="welcome-box-icon">
                    <img src={Callcenter}/>
                </span>
                <h5 className="welcome-h5">Call Center Access</h5>
                <p className="welcome-box-p">Best practices, tips and advice from your peers.</p>
            </div>
        </a> */}
      </div>
    </div>
  );
};

export default Home;
