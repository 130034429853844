import React from 'react'

const Payment = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={88}
    fill="none"
  >
    <circle cx={27} cy={30} r={3} className='svg-secondary' />
    <circle cx={27} cy={43} r={3} className='svg-primary' />
    <circle cx={27} cy={55} r={3} className='svg-tertery' />
    <circle cx={67.5} cy={20.5} r={18.5} className='svg-primary' />
    <path
      fill="#fff"
      d="M67.429 12.629a1.418 1.418 0 0 0 .312-.01 3.186 3.186 0 0 1 3.122 3.182 1.426 1.426 0 0 0 2.851 0 6.046 6.046 0 0 0-4.744-5.896v-1.37a1.426 1.426 0 1 0-2.852 0V9.97a6.036 6.036 0 0 0 1.56 11.868 3.185 3.185 0 1 1-3.185 3.185 1.426 1.426 0 1 0-2.851 0 6.046 6.046 0 0 0 4.476 5.831v1.563a1.426 1.426 0 0 0 2.852 0V30.92a6.035 6.035 0 0 0-1.292-11.932 3.185 3.185 0 0 1-.25-6.358v-.001Z"
    />
    <path fill="#001B23" d="M67.729.14a20.808 20.808 0 0 1 .002 0h-.002Z" />
    <path
      fill="#001B23"
      fillRule="evenodd"
      d="M66.153 72.723c0 5.902-4.78 10.689-10.682 10.698h-.03c-.09 0-.166-.005-.275-.014l-.015-.001c-.107-.009-.223.007-.405 0l-.534.015h-43.02v-.009l-.477-.022A10.7 10.7 0 0 1 .5 72.736V66.39c0-.465.402-.859.963-.859h10.584l.34-50.511c.004-.542.45-.994.926-.994h35.735l.12-.328C51.897 6.245 59.163.675 67.677.64h.083C78.645.64 87.5 9.514 87.5 20.4c0 10.887-8.859 19.763-19.743 19.763-.36 0-.728-.01-1.076-.03l-.527-.029v32.619Zm-63.802 0a8.886 8.886 0 0 0 8.875 8.847h37.87l-.73-.83a2.573 2.573 0 0 0-.306-.288l-.043-.036a2.25 2.25 0 0 1-.165-.143v-.001a10.686 10.686 0 0 1-3.154-7.57v-5.32H2.35v5.342Zm53.093 8.847h.027a8.848 8.848 0 0 0 8.83-8.849V39.867l-.4-.08a19.621 19.621 0 0 1-12.694-8.708l-.147-.232H35.772a.925.925 0 1 1 0-1.851h14.234l-.31-.703a19.605 19.605 0 0 1-1.679-7.954c.007-1.298.135-2.592.382-3.867l.115-.595H14.232l-.334 49.654h31.807a.815.815 0 0 1 .845.842v6.332a8.91 8.91 0 0 0 8.894 8.865ZM67.76 38.312c9.876-.01 17.88-8.015 17.89-17.89 0-9.88-8.01-17.892-17.89-17.892-9.881 0-17.89 8.01-17.89 17.89-.002 9.882 8.01 17.892 17.89 17.892Z"
      clipRule="evenodd"
    />
    <path
      fill="#001B23"
      fillRule="evenodd"
      d="M22.556 42.403c0-2.145 1.808-3.919 4.064-3.919 2.255 0 4.064 1.774 4.064 3.919s-1.809 3.919-4.064 3.919c-2.256 0-4.064-1.774-4.064-3.919Zm1.852 0c0 1.18 1.033 2.067 2.212 2.067 1.177 0 2.212-.886 2.212-2.067s-1.035-2.067-2.212-2.067c-1.178 0-2.212.886-2.212 2.067Z"
      clipRule="evenodd"
    />
    <path
      fill="#001B23"
      d="M35.771 43.322a.925.925 0 1 1 0-1.85H54.78a.926.926 0 0 1 0 1.85H35.771ZM26.625 31.578h.047l.036-.002a1.867 1.867 0 0 1-.083.002Z"
    />
    <path
      fill="#001B23"
      fillRule="evenodd"
      d="M22.556 30.01c0-2.146 1.808-3.92 4.064-3.92 2.255 0 4.064 1.775 4.064 3.92s-1.809 3.918-4.064 3.918c-2.256 0-4.064-1.773-4.064-3.918Zm3.564 2.068v-.055c-.956-.209-1.712-1.003-1.712-2.013 0-1.18 1.034-2.068 2.212-2.068 1.177 0 2.212.886 2.212 2.068 0 1.18-1.035 2.068-2.212 2.068h-.5ZM22.556 54.795c0-2.145 1.808-3.918 4.064-3.918 2.255 0 4.064 1.773 4.064 3.918 0 2.146-1.809 3.919-4.064 3.919-2.256 0-4.064-1.773-4.064-3.919Zm1.852 0c0 1.182 1.033 2.067 2.212 2.067 1.177 0 2.212-.886 2.212-2.067 0-1.18-1.035-2.067-2.212-2.067-1.178 0-2.212.887-2.212 2.067Z"
      clipRule="evenodd"
    />
    <path
      fill="#001B23"
      d="M35.771 53.946H54.78a.926.926 0 0 1 0 1.852H35.771a.925.925 0 1 1 0-1.852Z"
    />
  </svg>  )
}

export default Payment