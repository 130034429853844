import React from 'react'

const Enforcement = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={88}
    fill="none"
  >
    <path fill="#001B23" d="M40.436 63.855h-3.95v1.48h3.95v-1.48Z" />
    <path
      className='svg-secondary'
      d="M27.781 19.016a2.762 2.762 0 0 1 2.76-2.76h3.988c.416 0 .764.349.764.765a.772.772 0 0 1-.764.765H30.54c-.678 0-1.23.552-1.23 1.23v3.988a.772.772 0 0 1-.764.765.772.772 0 0 1-.765-.765v-3.988ZM45.498 16.257h3.988a2.762 2.762 0 0 1 2.76 2.759v3.988a.772.772 0 0 1-.765.765.772.772 0 0 1-.765-.765v-3.988c0-.678-.552-1.23-1.23-1.23h-3.988a.772.772 0 0 1-.765-.765c0-.416.348-.764.765-.764ZM32.767 23.004c0-.971.79-1.761 1.762-1.761h10.969c.971 0 1.761.79 1.761 1.761v3.989a.772.772 0 0 1-.764.764.772.772 0 0 1-.765-.764v-3.989a.24.24 0 0 0-.232-.232h-10.97a.24.24 0 0 0-.232.232v3.989a.772.772 0 0 1-.764.764.772.772 0 0 1-.765-.764v-3.989Z"
    />
    <path
      className='svg-secondary'
      fillRule="evenodd"
      d="M32.085 31.857h.682v2.116c0 .971.79 1.762 1.762 1.762h10.969c.971 0 1.761-.79 1.761-1.762v-2.116h1.569c.391 0 .758-.302.758-.71 0-.406-.367-.708-.758-.708h-1.795a.764.764 0 0 0-1.077 0H34.07a.764.764 0 0 0-1.077 0h-.908c-.392 0-.758.302-.758.709s.366.709.758.709Zm2.211 2.116v-2.116H45.73v2.116a.24.24 0 0 1-.232.233h-10.97a.24.24 0 0 1-.232-.233Z"
      clipRule="evenodd"
    />
    <path
      className='svg-secondary'
      d="M28.546 33.209c.416 0 .764.348.764.764v3.989c0 .677.552 1.23 1.23 1.23h3.989c.416 0 .764.348.764.764a.772.772 0 0 1-.764.764H30.54a2.763 2.763 0 0 1-2.759-2.758v-3.989c0-.416.349-.764.765-.764ZM51.48 33.209c.417 0 .765.348.765.764v3.989a2.762 2.762 0 0 1-2.759 2.758h-3.988a.772.772 0 0 1-.765-.764c0-.416.348-.765.765-.765h3.988c.678 0 1.23-.552 1.23-1.23v-3.988c0-.416.348-.764.765-.764Z"
    />
    <path fill="#001B23" d="M51.473 77.896h-1.672v1.48h1.672v-1.48Z" />
    <path
      fill="#001B23"
      fillRule="evenodd"
      d="M66.032 49.06c.458.592.695 1.287.695 2.012h.03V83H47.316V72.57c-.385-.43-1.14-1.42-1.628-2.812H27.046a5.777 5.777 0 0 1-5.77-5.77V10.134h.03C21.631 7.249 24.073 5 27.046 5h25.108c2.959 0 5.4 2.249 5.726 5.134h.03v30.034l8.107 8.862.015.03Zm-9.647-38.926a4.285 4.285 0 0 0-4.231-3.654H27.046a4.289 4.289 0 0 0-4.246 3.654h33.585Zm-33.63 53.854a4.294 4.294 0 0 0 4.292 4.29h18.271v-.014h6.821c.28 0 .552-.034.829-.074-.474-.784-.888-1.746-1.243-2.885-.444-1.48-.489-3.27-.415-4.734H22.756v3.417Zm29.399 5.77H47.27a7.057 7.057 0 0 0 1.51 2.22v2.293h16.452V51.072c0-.4-.133-.77-.37-1.095l-6.969-7.62v10.18l1.391 1.613c.68.295 2.93 1.494 4.04 4.482l-1.392.518c-1.03-2.748-3.183-3.627-3.348-3.694l-.188-.079-.503-.577-1.48-1.73-4.275-4.986-1.302 1.094a.732.732 0 0 1-1.036-.088.73.73 0 0 1 .09-1.036l1.212-1.021a2.533 2.533 0 0 0-2.796.089c-.994.71-1.308 2.087-.562 3.092l.015.015 5.385 7.53c-.435 2.284-.684 4.917 0 7.147.34 1.124.77 2.041 1.228 2.781.281.444.577.843.888 1.184 1.005 1.123 2.272 1.767 3.61 2.396l-.622 1.347c-1.67-.787-3.203-1.629-4.394-3.092a5.875 5.875 0 0 1-1.701.236Zm.65-23.184 3.626 4.217V11.613H22.756v47.478h28.673c.044-.429.089-.77.133-1.006l-5-6.998c-1.227-1.667-.766-4 .902-5.178 1.716-1.214 4.054-.918 5.34.665ZM48.782 75.75v5.77h16.481v-5.77H48.781Z"
      clipRule="evenodd"
    />
  </svg>  )
}

export default Enforcement