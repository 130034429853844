import React from 'react'

const Support = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={88}
    fill="none"
  >
    <circle cx={40} cy={77} r={4} className='svg-secondary' />
    <path
      fill="#000"
      fillRule="evenodd"
      d="m15.457 33.265.028-.372c1.22-15.747 14.973-27.525 30.72-26.306C60.26 7.674 71.426 18.84 72.514 32.893l.028.37.363.08c8.139 1.79 11.449 12.318 5.393 18.363-2.933 2.928-6.41 3.194-10.233 3.194a.79.79 0 0 1-.766-.767V33.868a.79.79 0 0 1 .766-.766h2.932l-.052-.547C69.55 17.673 56.354 6.739 41.472 8.134c-12.951 1.214-23.207 11.47-24.42 24.421l-.052.547h2.935a.79.79 0 0 1 .767.766v20.265a.79.79 0 0 1-.767.767h-3.033v3.033c0 10.066 7.878 17.684 18.403 18.184l.401.019.105-.387a4.556 4.556 0 1 1 3.203 5.59 4.594 4.594 0 0 1-3.23-3.308l-.09-.364-.374-.016c-11.478-.5-19.951-8.81-19.951-19.718v-3.221l-.387-.09a11.081 11.081 0 0 1-5.295-2.914A10.877 10.877 0 0 1 6.503 44a10.9 10.9 0 0 1 8.59-10.657l.364-.08Zm.77 20.03c.758.092 1.525.084 2.29.076.217-.002.435-.005.652-.005V34.635h-1.767a9.366 9.366 0 0 0-1.176 18.66ZM40.2 79.964a3.033 3.033 0 1 0 0-6.066 3.033 3.033 0 0 0 0 6.066ZM79.5 44c0-10.5-10.5-9-10.5-9v18s10.5 1.5 10.5-9Z"
      clipRule="evenodd"
    />
    <path
      className='svg-secondary'
      d="M49.523 57.326a.904.904 0 0 0 .616-.865v-12.97h4.846a1.893 1.893 0 0 0 1.892-1.89v-7.808A3.798 3.798 0 0 0 53.083 30H34.957A3.96 3.96 0 0 0 31 33.956v22.507c0 .396.241.735.615.865a.903.903 0 0 0 1.018-.298l1.028-1.303a.064.064 0 0 1 .053-.025c.02 0 .038.008.052.025l1.516 1.925a.91.91 0 0 0 .719.348.91.91 0 0 0 .719-.349l1.514-1.923a.066.066 0 0 1 .104 0l1.515 1.923c.349.443 1.088.443 1.437 0l1.515-1.923a.064.064 0 0 1 .053-.026c.021 0 .039.009.052.026l1.515 1.923c.35.443 1.088.443 1.438 0l1.514-1.923a.067.067 0 0 1 .052-.026.066.066 0 0 1 .053.026l1.023 1.3a.905.905 0 0 0 1.018.298Zm6.505-23.533V41.6a1.044 1.044 0 0 1-1.043 1.043H50.14v-8.85a2.948 2.948 0 0 1 2.944-2.944 2.948 2.948 0 0 1 2.945 2.944Zm-9.317 21.41-1.515 1.923a.067.067 0 0 1-.105 0l-1.515-1.923a.91.91 0 0 0-.718-.35.91.91 0 0 0-.72.35l-1.514 1.923a.066.066 0 0 1-.104 0l-1.515-1.923c-.349-.443-1.088-.443-1.437 0l-1.515 1.923a.065.065 0 0 1-.052.026.067.067 0 0 1-.053-.026l-1.516-1.924a.91.91 0 0 0-.718-.349.91.91 0 0 0-.72.349l-1.027 1.303c-.01.012-.03.037-.073.021-.045-.015-.045-.047-.045-.063V33.956a3.11 3.11 0 0 1 3.107-3.108h15.741a3.785 3.785 0 0 0-1.407 2.945v22.668c0 .016 0 .047-.044.063-.045.016-.065-.01-.074-.022l-1.024-1.299c-.349-.443-1.088-.443-1.438 0Z"
    />
    <path
      className='svg-secondary'
      d="m46.71 55.203-1.514 1.923a.067.067 0 0 1-.105 0l-1.515-1.923a.91.91 0 0 0-.718-.35.91.91 0 0 0-.72.35l-1.514 1.923a.066.066 0 0 1-.104 0l-1.515-1.923c-.349-.443-1.088-.443-1.437 0l-1.515 1.923a.065.065 0 0 1-.052.026.067.067 0 0 1-.053-.026l-1.516-1.924a.91.91 0 0 0-.718-.349.91.91 0 0 0-.72.349l-1.027 1.303c-.01.012-.03.037-.073.021-.045-.015-.045-.047-.045-.063V33.956a3.11 3.11 0 0 1 3.107-3.108h15.741a3.785 3.785 0 0 0-1.407 2.945v22.668c0 .016 0 .047-.044.063-.045.016-.065-.01-.074-.022l-1.024-1.299c-.349-.443-1.088-.443-1.438 0Z"
    />
    <path
      fill="#fff"
      d="M34.956 37.17h7.298a.425.425 0 0 0 0-.848h-7.298a.424.424 0 1 0 0 .848Zm11.25 2.593h-11.25a.424.424 0 1 0 0 .849h11.25a.424.424 0 1 0 0-.849Zm-11.25 4.29h7.298a.424.424 0 0 0 0-.848h-7.298a.424.424 0 1 0 0 .849Zm11.25 2.594h-11.25a.424.424 0 1 0 0 .848h11.25a.424.424 0 1 0 0-.848Zm-3.952 3.442h-7.298a.424.424 0 1 0 0 .848h7.298a.425.425 0 0 0 0-.848Z"
    />
  </svg>  )
}

export default Support