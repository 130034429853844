import "./css/style.css"
import Home from "./Components"
import {assignURLs} from "./Components/Site"
import { Helmet } from "react-helmet";


function App() {
const {faviconPath,customBranding,appleIcon} = assignURLs();
  return (
    <div className={`App ${customBranding.replace(/ /g, "").toLowerCase()}`}>
       <><Helmet>
        <title>{customBranding}</title>
        <link
          rel="icon"
          href={faviconPath} />
        {appleIcon && (
          <link
            rel="apple-touch-icon"
            type="image/png"
            href={appleIcon} />
        )}
      </Helmet><header className="App-header">
          <Home />
        </header></>
    </div>
  );
}

export default App;
